<template>
  <div>
    <CRow>
      <CCol sm="12" md="12">
        <CCard accent-color="info">
          <CCardHeader>
              <i :class="icon"></i>&nbsp; {{ caption }} 
              <button 
                :disabled="button" 
                type="button" 
                :class="btnClasses" 
                :style="ShowHide(btn_name)" 
                @click="action_modal">
                <i class="fas fa-plus"></i>&nbsp; {{btn_name}}
              </button>
              <button 
                :disabled="button1" 
                type="button" 
                :class="btnClasses" 
                :style="ShowHide1(btn_name1)" 
                @click="action_down">
                <i class="fas fa-file"></i>&nbsp; {{btn_name1}}
              </button>
          </CCardHeader>
          <CCardBody>
              <slot></slot>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  data: function () {
    return {
      show: true,
      isCollapsed: true
    }
  },
  props:{
      icon:{
          type: String,
          default: 'Cards'
      },
      caption:{
          type: String,
          default: 'Cards'
      },
      btn_name:{
          type: String,
          default: 'Cards'
      },
      btn_name1:{
          type: String,
          default: 'Cards'
      },
      button: Boolean,
      button1: {
        type: Boolean,
        default: false
      }
  },
  computed:{
    btnClasses () {
        return [`btn btn-${this.color || 'primary'} float-right`]
    }
  },
  methods: {
      action_modal: function(){
        this.$emit('show_modal',true,'store');
      },
      action_down: function(){
        this.$emit('show_down');
      },
      ShowHide: function(status){
        return status === "ocultar"
        ? "display:none"
        : "display:block";
      },
      ShowHide1: function(status){
        return status === "ocultar"
        ? "display:none"
        : "display:block";
      }
  }
}
</script>
